import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import { colors } from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken3, // #E53935
        secondary: colors.blue.lighten3, // #FFCDD2
        accent: colors.indigo.base // #3F51B5
      }
    }
  },
  icons: {
    iconfont: "mdi"
  }
});
